<template>
  <v-card
    flat
    class="cart-info-card rounded-md h-100 px-6 py-3 px-sm-3 py-sm-3"
  >
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6">
        <v-row
          no-gutters
          class="text-body-2 default--text font-weight-bold my-5"
        >
          <v-col cols="6">
            <span>{{ $t("orders.header.orderNumber") }}</span>
          </v-col>
          <v-col cols="6">
            <span>{{ $t("orders.header.status") }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="text-body-2 my-5">
          <v-col cols="6">
            <span class="primary--text font-weight-600"
              >#{{ order.orderId }}</span
            >
          </v-col>
          <v-col cols="6">
            <OrderStatusBadge v-bind:order="order" class="default--text" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="order-actions d-flex justify-center justify-sm-end align-center"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.deliveryAddress.addressType != 'home'"
              color="primary"
              icon
              large
              @click.stop.prevent="openQrCodePopup()"
              depressed
            >
              <v-icon>$qrcode</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.qrCode") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="deleteOrder(order.orderId)"
              depressed
              icon
              large
              v-if="order.isDeletable"
            >
              <v-icon>$delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.delete") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.isEditable"
              @click="editOrder(order.orderId)"
              color="primary"
              icon
              large
              depressed
            >
              <v-icon>$edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.isPayable"
              @click="showPayment"
              color="primary"
              icon
              large
              depressed
            >
              <v-icon>$creditCard</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.retryPayment") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
              color="primary"
              icon
              large
              @click.stop.prevent="addAllToCart(order.orderId)"
              depressed
              :aria-label="$t('orders.button.reorder')"
            >
              <v-icon>$cartReorder</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.reorder") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="printOrder"
              color="primary"
              depressed
              icon
              large
              :aria-label="$t('orders.button.print')"
              ><v-icon>$print</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.print") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="order.hasBill">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.orderStatusId == 8 || order.orderStatusId == 18"
              :href="
                `/ebsn/api/order/document?document_type_id=12&order_id=${order.orderId}&type=pdf`
              "
              @click.stop.prevent="downloadDocument(order.orderId)"
              target="_blank"
              color="primary"
              depressed
              icon
              large
            >
              <v-icon>$receipt</v-icon>
            </v-btn></template
          >
          <span>{{ $t("orders.button.ddr") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.order-actions {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-btn {
      margin: 0px 8px;
    }
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderQrCode from "@/components/orders/OrderQrCode.vue";

import downloadDocumentMixin from "~/mixins/downloadDocument";

export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge },
  props: ["order"],
  computed: {
    receiptUrl() {
      let documentTypeId = 12;
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=${documentTypeId}&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    downloadDocument(orderId) {
      var url = window.event.currentTarget.href;
      this.handleReceiptDownload(url, orderId);
    },
    openQrCodePopup() {
      global.vm.$dialog.show(OrderQrCode, {
        waitForResult: true,
        fullscreen: global.vm.$vuetify.breakpoint.xsOnly,
        qrFullscreen: global.vm.$vuetify.breakpoint.xsOnly,
        order: this.order
      });
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
