<template>
  <div class="order-detail-table">
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.xsOnly"
      class="px-5 order-table-header"
      align="center"
    >
      <v-col :cols="delivered ? '4' : '6'" class="d-flex justify-start">
        {{ $t("orders.header.product") }}
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        {{ $t("orders.header.unitPrice") }}
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        {{ $t("orders.header.qtyOrdered") }}
      </v-col>
      <v-col cols="2" class="d-flex justify-center" v-if="delivered">
        {{ $t("orders.header.qtyPrepared") }}
      </v-col>
      <v-col cols="2" class="d-flex justify-end">
        {{ $t("orders.header.totalPrice") }}
      </v-col>
    </v-row>

    <v-list>
      <div v-for="item in suborders" v-bind:key="item.itemId">
        <template v-if="!item.wrongPickedProduct">
          <div v-if="item.deliveredType == 'alternative'" class="proposal">
            {{ $t("order.proposal") }}
          </div>
          <div
            v-if="item.deliveredType == 'partial'"
            class="partial-delivered-text"
          >
            {{ $t("order.partialDelivered") }}
          </div>
          <v-list-item
            class="order-item py-4"
            :class="delivered ? item.deliveredType : ''"
          >
            <order-detail-row
              :item="item"
              :order="order"
              :delivered="delivered"
              @toggleItemForRefund="toggleItemForRefund"
              @reload="reload"
            />
          </v-list-item>
        </template>
      </div>
    </v-list>
  </div>
</template>
<style lang="scss">
.order-detail-table {
  .order-table-header {
    font-weight: 600;
    text-align: center;
  }
  .weight-unit {
    font-size: 10px;
  }
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-lighten1);
  }

  .price {
    .old-price,
    .weight-unit,
    .price-um {
      font-size: 10px;
    }
    .cur-price {
      font-size: 18px;
      font-weight: 500;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .cur-price {
        font-size: 16px;
      }
    }
  }
  .qty-box {
    background: white;
    color: var(--v-default-base);
    padding: 10px 15px;
    min-width: 120px;
    border: 1px solid var(--v-grey-darken1);
    border-radius: 4px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto;
      width: 95%;
    }
  }

  .gross-total {
    font-size: 18px;
  }
  .order-item {
    border-bottom: 1px solid var(--v-grey-base);
    margin-bottom: 3px;
  }
  .alternative {
    //border-color: #26b426;
    border: 2px solid #26b426;
    border-radius: 4px;
  }
  .replaced {
    border: 2px solid #e2001a;
    border-radius: 4px;
  }
  .proposal {
    color: #26b426;
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;
  }
  .partial {
    border: 2px solid #ec9706;
    border-radius: 4px;
  }
  .partial-delivered-text {
    color: #ec9706;
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;
  }
  .not-available {
    text-transform: uppercase;
    font-size: 12px !important;
    color: #e2001a;
    font-weight: 600;
    text-align: end;
  }
}
</style>
<script>
import OrderDetailRow from "@/components/orders/OrderDetailRow.vue";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "OrderDetailTable",
  props: {
    order: { type: Object },
    suborders: { type: Array },
    delivered: { type: Boolean }
  },
  components: {
    OrderDetailRow
  },
  methods: {
    toggleItemForRefund(item) {
      this.$emit("toggleItemForRefund", item);
    },
    reload(orderId) {
      this.$emit("reload", orderId);
    }
  },
  mounted() {
    for (const subOrder of this.order.suborders) {
      AnalyticsService.viewProducts(subOrder.suborderItems, "Order", 0);
    }
  }
};
</script>
