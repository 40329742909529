<template>
  <v-row no-gutters align="center">
    <v-col
      cols="12"
      :sm="delivered ? '4' : '6'"
      class="d-flex align-center mb-3 mb-md-0"
    >
      <router-link
        :to="{ name: 'Product', params: { slug: item.product.slug } }"
        :aria-label="item.product.slug"
        :title="item.product.slug"
      >
        <img
          :src="item.product.mediaURL"
          class="mr-4"
          height="100px"
          width="100px"
          :alt="item.product.name"
          onerror="this.onerror=null;this.src='/no-icon.png'"
        />
      </router-link>
      <div class="d-flex flex-column default--text">
        <span class="text-body-2"> {{ item.product.name }}</span>
        <span class="text-caption font-weight-bold">{{
          item.product.shortDescr
        }}</span>
        <span class="text-caption">{{ item.product.description }}</span>
        <span
          class="weight-unit font-weight-bold"
          v-if="
            item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
              item.product.productInfos.PACKAGE_DESCR
          "
        >
          {{ item.product.productInfos.PACKAGE_DESCR }}
        </span>
        <span class="text-caption grey--text text--darken-1" v-if="itemOption">
          <i>{{ $t("product.preferences") }}: {{ itemOption }}</i>
        </span>
      </div>
    </v-col>
    <v-col :cols="delivered ? '3' : '4'" sm="2" class="d-flex justify-center">
      <OrderItemPrice :item="item"></OrderItemPrice>
    </v-col>
    <v-col :cols="delivered ? '3' : '4'" sm="2" class="d-flex justify-center">
      <OrderDetailQuantity :item="item" :delivered="false" />
    </v-col>
    <v-col
      v-if="delivered"
      :cols="delivered ? '3' : '4'"
      sm="2"
      class="d-flex justify-center"
    >
      <OrderDetailQuantity :item="item" :delivered="delivered" />
    </v-col>
    <v-col
      :cols="delivered ? '3' : '4'"
      sm="2"
      offset-sm="0"
      class="d-flex justify-end"
    >
      <OrderGrossTotal
        v-if="!delivered || item.deliveredType != 'replaced'"
        :item="item"
      ></OrderGrossTotal>
      <div v-else class="not-available">{{ $t("order.notAvailable") }}</div>
    </v-col>
    <v-col
      cols="12"
      v-if="order.isRefundable && item.deliveredType != 'replaced'"
      ><div class="d-flex justify-end">
        <v-checkbox
          :label="$t('refund.requestRefund')"
          hide-details
          @click="toggleItemForRefund"
        ></v-checkbox></div
    ></v-col>
    <v-col
      cols="12"
      v-if="order.orderStatusId == 6 && item.deliveredType == 'alternative'"
      class="justify-end"
    >
      <v-radio-group
        :value="item.orderItemInfos.SUBSTITUTE_CONFIRM"
        row
        hide-details
        readonly
        class="substitute-radio-group mt-1 font-weight-600"
      >
        <v-radio
          label="Rifiuta sostitutivo"
          value="refuse"
          color="default"
          @click="substitute(false)"
        ></v-radio>
        <v-radio
          label="Accetta sostitutivo"
          value="accept"
          color="default"
          @click="substitute(true)"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.substitute-radio-group {
  .v-input--radio-group__input {
    justify-content: end;
  }
}
</style>
<script>
import OrderItemPrice from "@/components/orders/OrderItemPrice.vue";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import find from "lodash/find";
import RefundService from "@/service/refundService";

export default {
  name: "OrderDetailRow",
  props: {
    item: { type: Object },
    order: { type: Object },
    delivered: { type: Boolean }
  },
  components: { OrderItemPrice, OrderGrossTotal, OrderDetailQuantity },
  computed: {
    hasPromo() {
      return (
        this.item.product.priceStandardDisplay &&
        this.item.unitPrice !== this.item.product.priceStandardDisplay
      );
    },
    itemAdjustment() {
      if (
        this.order.orderAdjustmentSummary &&
        this.order.orderAdjustmentSummary.orderAdjustments
      ) {
        let adj = find(
          this.order.orderAdjustmentSummary.orderAdjustments,
          orderAdjustment =>
            orderAdjustment.orderItemId == parseInt(this.item.orderItemId)
        );
        return adj;
      }
      return null;
    },
    itemOption() {
      return this.item.orderItemInfos?.size;
    }
  },
  methods: {
    toggleItemForRefund() {
      this.$emit("toggleItemForRefund", this.item);
    },
    toggleSubstitute(accept) {
      RefundService.substitute(
        this.order.orderId,
        this.item.orderItemId,
        accept
      ).then(() => {
        this.reload(this.order.orderId);
      });
    },
    async substitute(accept) {
      if (accept) {
        this.toggleSubstitute(accept);
      } else {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.rejectSuggestedProduct")
        });
        if (res) {
          this.toggleSubstitute(accept);
        }
      }
    },
    reload(orderId) {
      this.$emit("reload", orderId);
    }
  }
};
</script>
