<template>
  <div class="qr-code text-center">
    <img
      class="qr-code-img"
      :src="imgsrc"
      alt="Qr Code per ritirare l'ordine"
    />
  </div>
</template>
<style lang="scss">
.qr-code {
  .qr-code-img {
    max-width: 100%;
  }
}
</style>
<script>
export default {
  name: "OrderQrCode",
  props: {
    order: { type: Object, required: true },
    qrWidth: { type: Number, required: false },
    qrHeight: { type: Number, required: false },
    qrFullscreen: { type: Boolean, default: false }
  },

  computed: {
    imgsrc() {
      if (
        this.order.deliveryAddress.addressType == "drive" ||
        this.order.deliveryAddress.addressType == "work"
      ) {
        return `https://api.qrserver.com/v1/create-qr-code/?size=${this.qrWidth}x${this.qrHeight}&data=${this.order.orderId}`;
      } else if (this.order.deliveryAddress.addressType == "locker") {
        return `https://api.qrserver.com/v1/create-qr-code/?size=${this.qrWidth}x${this.qrHeight}&data=${this.order.orderInfos.PIN_CODE}`;
      }
      return "";
    }
  }
};
</script>
