<template>
  <v-card class="qr-code-dialog-container">
    <v-card-title class="text-center justify-center">
      {{ $t("qrCode.title", { orderId: order.orderId }) }}
    </v-card-title>
    <v-card-text>
      <QrCode
        :order="order"
        :qrWidth="qrWidth"
        :qrHeight="qrHeight"
        :qrFullscreen="qrFullscreen"
      />
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn @click="$emit('submit', false)" depressed color="primary">
        {{ $t("common.close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.qr-code-dialog-container {
}
</style>
<script>
import QrCode from "@/components/QrCode";
export default {
  name: "OrderQrCode",
  components: { QrCode },
  props: {
    order: { type: Object, required: true },
    qrWidth: { type: Number, default: 370 },
    qrHeight: { type: Number, default: 370 },
    qrFullscreen: { type: Boolean, default: true }
  }
};
</script>
